/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import PageHeader from "../../components/elements/PageHeader/PageHeader";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(PageHeader, {
    title: "こんな人に学んでほしい"
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Kpop・ドラマが好き。"), "\n", React.createElement(_components.li, null, "韓国に留学したい。"), "\n", React.createElement(_components.li, null, "韓国のエンタメが好き。"), "\n", React.createElement(_components.li, null, "韓国に住みたい。"), "\n", React.createElement(_components.li, null, "韓国語が学びたい。"), "\n", React.createElement(_components.li, null, "推しの人と話したい。"), "\n", React.createElement(_components.li, null, "韓国人の友達が欲しい。"), "\n", React.createElement(_components.li, null, "韓国語を使って働きたい。"), "\n", React.createElement(_components.li, null, "アイドルになりたい。"), "\n", React.createElement(_components.li, null, "韓国好きの友達を作りたい。"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
